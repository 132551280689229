











import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RatioWrapper',
  props: {
    ratio: {
      type: String,
      required: false,
      default: '3:4'
    },
    ratioDesktop: {
      type: [String, undefined],
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const transformedRatioClass = computed<string>(() => {
      const ratio = props.ratio.replace(':', '-');
      const ratioDesktop = props.ratioDesktop?.replace(':', '-') ?? '';
      const desktopRatioClass = ratioDesktop
        ? `desktop:aspect-${ratioDesktop}`
        : '';

      return `aspect-${ratio} ${desktopRatioClass}`;
    });

    return {
      transformedRatioClass
    };
  }
});
